/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// new approach
import React from 'react';
import { AudioPlayerProvider } from '../contexts/AudioPlayerState';
import AudioPlayerWrapper from './audio-player-wrapper';
import { Helmet } from 'react-helmet';
import Header from './header';
import Icons from './Icons';
import Footer from './Footer';
import '../styles/devshows.scss';
// import PropTypes from "prop-types";

const Layout = ({ children }) => {
  return (
    <AudioPlayerProvider>
      <Helmet>
        <html lang="en-US" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#7a3700" />
        <meta name="msapplication-TileColor" content="#e6e1d6" />
        <meta name="theme-color" content="#e6e1d6"></meta>
        <body className="theme-1" />
      </Helmet>
      <div className="page">
        <Header />
        <div>
          <main>{children}</main>
        </div>
      </div>
      <Footer />
      <Icons />

      <AudioPlayerWrapper />
    </AudioPlayerProvider>
  );
};

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout;

// ------------- STUFF ----------------
// <AudioPlayer
// src={contextAudio.srcUrl}
// onPlay={e => console.log("onPlay")}
// progressJumpStep="10000"
// />

// {latest[0].episodes[0].enclosure.url}

// ------------- SCRAPS ----------------
// ____________________
// orig approach
// import React, { useContext} from "react";
// import { AudioPlayerContext, AudioPlayerProvider } from '../contexts/AudioPlayerState';
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';
// ____________________
// console.log('new src for audio passed to Layout:', contextAudio.srcUrl);

// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `)
// <Icons />
// <Header siteTitle={data.site.siteMetadata.title} />
// <Footer />

// This results in a 404 because Gatsby turns the logo woff2 into a data:uri that it embeds in the head css. So, no jomhuria-subset.woff2 is put in /static/. Even so, not sure I have the right URL below, not that it would matter in this case.
// <link
// rel="preload"
// as="font"
// type="font/woff2"
// href="/styles/fonts/jomhuria-subset.woff2"
// crossorigin
// ></link>

/* 
https://fonts.googleapis.com/css2?family=Bitter&family=Faustina:wght@400;500&family=Changa+One&family=Jomhuria&text=DevShows&family=Merriweather&family=Archivo&family=Source+Sans+Pro&family=Roboto&family=Rubik:wght@500;700&display=swap
*/

/* When using Google Fonts for logo and headings
<link
  href="https://fonts.gstatic.com"
  rel="preconnect"
  crossorigin
></link>

<link
  href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap"
  rel="stylesheet"
></link>

<link
    href="https://fonts.googleapis.com/css2?family=Jomhuria&text=DevShows&display=block"
    rel="stylesheet"
></link>

<link
  href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap"
  rel="stylesheet"
></link>
*/
