const React = require("react");
const Layout = require("./src/components/layout").default;

// Ref: https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement

/**
 * Make audio player persist across pages without reinstantiating as you browse site. 
 * Audio component is in Layout component.
 */
exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
