/**
 * Context for AudioPlayer component
 */
// ref: Traversy Media - Build an Expense Tracker | React Hooks & Context API
// https://www.youtube.com/watch?v=XuFDcZABiDQ

import React, { createContext, useReducer } from 'react';

// Initial state of context.
// b.mp3 is an empty 250-millisecond track. By initializing the player, this facilitates making an episode play right away when use a Play button.
const initialState = {
  audioHadNotBeenPlayedBefore: true,
  header: '-',
  playerRefState: '',
  srcUrl: '/b.mp3',
};

// Create context
export const AudioPlayerContext = createContext(initialState);

/**
 * Provider
 * @param {object} children
 */
export const AudioPlayerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // ---- Actions ----
  // Action 1: Change MP3 URL in audio player
  function changeAudioPlayerSrc(srcUrl, header = '') {
    // console.log(
    //   '[AudioPlayerState AudioPlayerProvider] changeAudioPlayerSrc Action fired: ',
    //   srcUrl
    // );

    dispatch({
      type: 'CHANGE_AUDIO_PLAYER_SRC',
      payload: srcUrl,
    });

    dispatch({
      type: 'CHANGE_AUDIO_PLAYER_HEADER',
      payload: header,
    });
  }

  // Action 2: Set the audio player ref so it is available to the BtnPlay component. Necessary for all Safari playback.
  function setPlayerRefState(reff) {
    dispatch({
      type: 'SET_PLAYER_REF_STATE',
      payload: reff,
    });
  }

  // Action 3: Set audioHadNotBeenPlayedBefore. Because the Boolean initialState is 'true', the first time audio is played the player slides open. Then it is set to 'false' so the player doesn't slide open every time audio is played (if it had been closed in the interim).
  function setAudioHadNotBeenPlayedBefore(bool) {
    dispatch({
      type: 'SET_AUDIO_HAD_NOT_BEEN_PLAYED_BEFORE',
      payload: bool,
    });
  }

  return (
    <AudioPlayerContext.Provider
      value={{
        audioHadNotBeenPlayedBefore: state.audioHadNotBeenPlayedBefore,
        header: state.header,
        playerRefState: state.playerRefState,
        srcUrl: state.srcUrl,
        changeAudioPlayerSrc,
        setPlayerRefState,
        setAudioHadNotBeenPlayedBefore,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

/**
 * Reducer (how to specify state changes in response to certain actions to our context)
 */
export const AppReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_AUDIO_PLAYER_HEADER':
      return {
        ...state,
        header: action.payload,
      };
    case 'CHANGE_AUDIO_PLAYER_SRC':
      return {
        ...state,
        srcUrl: action.payload,
      };
    case 'SET_PLAYER_REF_STATE':
      return {
        ...state,
        playerRefState: action.payload,
      };
    case 'SET_AUDIO_HAD_NOT_BEEN_PLAYED_BEFORE':
      return {
        ...state,
        audioHadNotBeenPlayedBefore: action.payload,
      };
    default:
      return state;
  }
};
