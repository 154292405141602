import React from 'react';
import { Link } from 'gatsby';

export default function Header() {
  return (
    <header className="header">
      <div className="header__inner">
        <p className="logo">
          <Link to="/">
            {' '}
            <svg className="icon icon--logo-header">
              <use xlinkHref="#icon-logo" />
            </svg>
            Dev Shows
          </Link>
        </p>

        <nav className="navWrap">
          <ul className="nav">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/podcasts">Podcasts</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/search" className="svg-search">
                <span className="visuallyHidden">Search</span>
                <svg
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  className="icon--search"
                >
                  <path
                    d="M12.906 14.32a8 8 0 111.414-1.414l5.337 5.337-1.414 1.414-5.337-5.337zM8 14A6 6 0 108 2a6 6 0 000 12z"
                    fillRule="evenodd"
                  />
                </svg>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
