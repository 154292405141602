import React from 'react';
// import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <svg className="icon icon--logo-footer">
          <use xlinkHref="#icon-logo" />
        </svg>

        <p>
          <small>
            Please note that Dev Shows isn’t affiliated with the featured
            podcasts. Each podcast’s content (logo and other images, audio, and
            text) is property of the show itself. Their content is retrieved
            from the show’s RSS feed and may contain the occasional error, such
            as an MP3 audio URL that doesn't work. And, of course, any opinions
            expressed, or product endorsements made, by a show’s host(s) or
            guest(s) are theirs alone.
          </small>
        </p>
        <p>
          <small>All other content is &copy; 2020–21 Dev Shows.</small>
        </p>
        <p>
          <small>
            Made by{' '}
            <a href="https://www.bruceontheloose.com" rel="external">
              Bruce
            </a>
            . <br />
            I’m a freelance developer available for hire.
          </small>
        </p>
      </div>
    </footer>
  );
}
