// Utilities

/**
 * Turn an episode title into the foldername that is used in path to episode page.
 * @param {string} episodeTitle The title of a show episode
 */
// !!! IMPORTANT !!!
// If add, remove, or modify any .replace() operation below, have to make the same change in /back-end/get-feeds-md.js.

// replace hash at beginning and end. no longer needed.
// .replace(/^#/, '')
// .replace(/#$/, '')
exports.createEpisodeFoldername = function (episodeTitle) {
  let episodeFolder = episodeTitle.toLowerCase();
  episodeFolder = episodeFolder
    .replace(/ /g, '-')
    .replace(/#/g, '')
    .replace(/,/g, '')
    .replace(/:/g, '')
    .replace(/%/g, '-percent-')
    .replace(/(-&+-)/g, '-and-')
    .replace(/\./g, '')
    .replace(/'/g, '')
    .replace(/‘/g, '')
    .replace(/’/g, '')
    .replace(/"/g, '')
    .replace(/“/g, '')
    .replace(/”/g, '')
    .replace(/–/g, '-')
    .replace(/—/g, '-')
    .replace(/(-+){2}/g, '-')
    .replace(/\//g, '')
    .replace(/!/g, '')
    .replace(/\?/g, '')
    .replace(/^-/, '')
    .replace(/-$/, '')
    .trim();

  return episodeFolder;
};

/**
 * Check if localStorage available
 * @returns {Boolean}
 */
exports.localStorageIsAvailable = function () {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

// ----- NOTES -----
// ES6 Modules syntax: export function createEpisodeFoldername(episodeTitle) {
// I didn't use that because gatsby-node.js needs utils, too, and node doesn't natively support ES6 Modules.
