import React, { useContext, useRef, useState, useEffect } from 'react';
import { AudioPlayerContext } from '../contexts/AudioPlayerState';

// Audio player is https://www.npmjs.com/package/react-h5-audio-player.
import AudioPlayer from 'react-h5-audio-player';
import AudioPlayerSelectRate from '../components/audio-player-select-rate';
import 'react-h5-audio-player/lib/styles.css';

const utils = require('../utilities/utils');

/**
 * Wrapper for audio player.
 * Includes the player component and my custom component for selecting the playback rate.
 */
export default function AudioPlayerWrapper() {
  const contextAudio = useContext(AudioPlayerContext);
  const { setAudioHadNotBeenPlayedBefore } = useContext(AudioPlayerContext);

  // console.log(
  //   '[AudioPlayerWrapper] new src for audio passed to Layout:',
  //   contextAudio.srcUrl
  // );

  // Audio player
  const playerRef = useRef();

  // Whether player is slid open or closed
  const [playerToggle, setPlayerToggle] = useState('isClosed');

  /**
   * Update context player ref value so BtnPlay has access to it.
   * Result is all Play btns work in Safari (desktiop and iOS).
   */
  // The useEffectOnlyOnce comes from an invaluable tip by Paul Razvan Berg in the comments of:
  // https://css-tricks.com/run-useeffect-only-once/.
  const useEffectOnlyOnce = (func) => useEffect(func, []);
  const { setPlayerRefState } = useContext(AudioPlayerContext);

  useEffectOnlyOnce(() => {
    setPlayerRefState(playerRef);
  });

  /**
   * Collapse or expand audio player
   * @param {object} event Click event object
   */
  const handleOpenClose = () => {
    const newToggleState = playerToggle === 'isOpen' ? 'isClosed' : 'isOpen';
    setPlayerToggle(newToggleState);
  };

  /**
   * Runs when audio player play event fires.
   */
  function handlePlay() {
    // console.log('[AudioPlayerWrapper] handlePlay()');

    if (utils.localStorageIsAvailable && localStorage.getItem('playbackRate')) {
      // console.log(
      //   '[AudioPlayerWrapper] handlePlay(): play audio with playbackRate from localStorage'
      // );
      playerRef.current.audio.current.playbackRate = localStorage.getItem(
        'playbackRate'
      );
    }

    // When audio played for first time, make player slide open
    if (contextAudio.audioHadNotBeenPlayedBefore) {
      setPlayerToggle('isOpen');
      setAudioHadNotBeenPlayedBefore(false);
    }
  }

  /**
   * Playback error
   */
  function handlePlayError(e) {
    console.log('Play error.'); // , e
  }

  return (
    <>
      <div className={`audioPlayerWrapper audioPlayerWrapper--${playerToggle}`}>
        <h2 className="visuallyHidden">Audio Player</h2>

        <button
          className={`btnTogglePlayer btnTogglePlayer--${playerToggle}`}
          onClick={handleOpenClose}
        >
          <span className="btnTogglePlayer__close">
            &gt; <span className="visuallyHidden">collapse audio player</span>
          </span>
          <span className="btnTogglePlayer__open">
            &gt; <span className="visuallyHidden">expand audio player</span>
          </span>
        </button>

        <AudioPlayerSelectRate playerRef={playerRef} />
        <AudioPlayer
          src={contextAudio.srcUrl}
          header={contextAudio.header}
          layout="stacked-reverse"
          onPlay={handlePlay}
          onPlayError={handlePlayError}
          progressJumpStep="7000"
          customAdditionalControls={[]}
          ref={playerRef}
        />
      </div>
    </>
  );
}

// --------------- SCRAPS ----------------

//           autoPlayAfterSrcChange={true}

/**
 * Fires when test play() button pressed. Not part of final site.
 */
/* UNCOMMENT THIS IF USE THE TEST PLAY BUTTON
function handlePlayTest() {
  // current property is pointing to the input element
  // when a component mounts to dom
  // nameRef.current.focus()
  playerRef.current.audio.current.play();

  if (utils.localStorageIsAvailable) {
    playerRef.current.audio.current.playbackRate = localStorage.getItem(
      'playbackRate'
    );
  }
}
*/

// onPlay={(e) => console.log(`play audio`)}

//           style={{ position: 'fixed', bottom: '0', left: '0' }}

// ::: putting a play button in the page :::
// function handlePlay(){
//   // current property is pointing to the input element
//   // when a component mounts to dom
//   // nameRef.current.focus()
//   playerRef.current.audio.current.play()
// }

// <button onClick={handlePlay}>test play()</button>

// ::: not sure if this helps
// https://github.com/lhz516/react-h5-audio-player/issues/8
// <AudioPlayer
//   hidePlayer={this.state.hideShowPlayer}
//   src={this.state.src}
//   controls={true}
//   autoPlay={false}
//   ref={(a) => this._audioo = a}
// />

// ::: from different player :::
// <ReactAudioPlayer
// ref={(element) => { this.rap = element; }}
// />
// register a onclick do this.rap.audioEl.play()

// ::: other stuff :::
// i tried adding this prop to <AudioPlayer />:
//    onCanPlay={handlePlay}
// worked in Chrome but not Safari because, seemingly, user permissions in Safari for autoplay set to don't allow

// onPlay={e => console.log(`play: ${playerRef.current.audio.current}`)}
