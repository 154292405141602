import React, { useState } from 'react';

const utils = require('../utilities/utils');

/**
 * Select playback rate of audio player
 * @param {*} playerRef Ref to the audio player so can set its playback rate from here
 */
export default function AudioPlayerSelectRate({ playerRef }) {
  const [playbackRate, setPlaybackRate] = useState(getDefaultPlaybackRate());

  /**
   * Get default playback rate to display in select box.
   * @returns {string} Playback rate from localStorage if available.
   */
  function getDefaultPlaybackRate() {
    if (
      utils.localStorageIsAvailable() &&
      localStorage.getItem('playbackRate')
    ) {
      // console.log('get playback rate from localStorage to use as default rate');
      return localStorage.getItem('playbackRate');
    } else {
      return '1.0';
    }
  }

  /**
   * Saves user's playback rate preference to localStorage if available.
   * @param {string} rate Playback rate user chose from select
   */
  function savePlaybackRate(rate) {
    if (utils.localStorageIsAvailable()) {
      // console.log(`save user's chosen playback rate in localStorage`);
      localStorage.setItem('playbackRate', rate);
    } else {
      // console.log('ls not avail');
    }
  }

  function handlePlaybackRate(event) {
    const newPlaybackRate = event.target.value;
    setPlaybackRate(newPlaybackRate);
    playerRef.current.audio.current.playbackRate = newPlaybackRate;
    savePlaybackRate(newPlaybackRate);
  }

  return (
    <form className="audioPlaybackRate">
      <fieldset>
        <label htmlFor="playback-rate" className="audioPlaybackRate__label">
          Speed:
        </label>
        <select
          id="playback-rate"
          defaultValue={playbackRate}
          onInput={handlePlaybackRate}
        >
          <option value="0.5">0.5</option>
          <option value="0.75">0.75</option>
          <option value="1.0">1.0</option>
          <option value="1.25">1.25</option>
          <option value="1.5">1.5</option>
          <option value="1.75">1.75</option>
          <option value="2.0">2.0</option>
          <option value="2.25">2.25</option>
          <option value="2.5">2.5</option>
        </select>
      </fieldset>
    </form>
  );
}
